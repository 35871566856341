import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n7 from "../../images/news7.jpg";
import pic3 from "../../images/Pic-3.png";
import NFooter from "../../components/nFooter";

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="iTelaSoft CEO Honored at the IESL NSW Awards"
        description="Sri Lankan engineer, entrepreneur &amp; the CEO of iTelaSoft, Indaka Raigama, has been recognized and awarded in the Institute of Engineers Sri Lanka (IESL), New South Wales Chapter awards ceremony held in Australia recently."
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <img alt="IESL NSW Awards" src={n7} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">NEWS | JUNE 12, 2019</div>
              <h1>iTelaSoft CEO Honored at the IESL NSW Awards</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12 col-md-12">
            <div className="content-body">
              <div className="content">
                <p>
                  Sri Lankan engineer, entrepreneur &amp; the CEO of iTelaSoft,
                  Indaka Raigama, has been recognized and awarded in the
                  Institute of Engineers Sri Lanka (IESL), New South Wales
                  Chapter awards ceremony held in Australia recently. The award
                  was given under the category of “Highly Commended Innovation”
                  on a product developed by iTelaSoft under his guidance for the
                  Telecommunication Industry. Dr. Marlene Kanga, the President
                  of the World Federation of Engineering Organizations expressed
                  her pleasure in innovation and R&D contributions made by
                  entrepreneurs in addition to academia while handing over the
                  award to Mr. Indaka Raigama.
                </p>
                <div className="p-2">
                  <img
                    className="img-fluid"
                    alt="Sri Lankan engineer.."
                    src={n7}
                  />
                </div>
                <p>
                  IESL is an apex body representing Sri Lankan engineering
                  professionals, with its regional chapters in many countries.
                  Since 1906, the organization is providing direction and
                  momentum to the engineering community in Sri Lanka and abroad.
                  IESL New South Wales Chapter is active in Australia for the
                  last 10 years and its annual convention consists of a
                  conference with a presentation of new innovations and
                  contributions to the engineering practice. Each year, a series
                  of scholars and engineers are recognized for their service and
                  contribution to the industry. The 2019 convention was held
                  under the leadership of Engineer Rohitha Silva, the Chair of
                  IESL New South Wales Chapter, with the presence of Prof. T.M.
                  Pallewatta, the President of the IESL, Sri Lanka. Speaking of
                  the goals of the organization, Mr. Rohitha Silva mentioned
                  that, “as a professional community force, we want to pass
                  knowledge to the next generation and encourage young
                  engineering practitioners to become active, prominent and
                  influential. Promoting women in engineering is another key
                  focus to increase diversity and growth in the industry”. The
                  awarded product, “Mobile Network Performance Monitor” is a
                  solution for monitoring & diagnosing various services &
                  capabilities run on mobile networks to reduce effort & costs
                  of monitoring, testing & diagnosing risks associated with SLA
                  commitments. Speaking on the product, Eng. Indaka Raigama said
                  “The mobile provider space is filled with multiple services
                  (voice, messaging, broadband data, narrowband data, message
                  brokers), standards (2G, 3G, 4G & 5G) & applications such as
                  USSD, STK, IVR based apps, handset-based apps, and IoT apps.
                  During the deployment period as well as in the service period,
                  it takes a significant effort in testing an end to end
                  capability for telecommunication operators. With this product,
                  we essentially wanted to make it more practical and
                  economical”.
                </p>
                <div className="p-2">
                  <img
                    className="img-fluid"
                    alt="IESL is an apex body"
                    src={pic3}
                  />
                </div>
                <p>
                  The monitoring platform developed by iTelaSoft is a centrally
                  managed, but distributed test execution framework for
                  automatically testing multiple capabilities or services with
                  many test scenarios. The platform was conceptualized, designed
                  & fabricated completely by the iTelaSoft team in Australia &
                  Sri Lanka. The product is currently been used in many
                  countries & has been a clear value enhancer to its clients.
                  iTelasoft IoT group consists of electronics & software
                  engineers who create innovative solutions for their clients
                  globally. “I’m really proud of our team who built this product
                  and even leading us towards an award! I want to specifically
                  mention a few names among the team, Manoj Fernando, Tharindu
                  Hettiarachchi and Chrishmal Fernando who contributed in
                  production line with ideas, engineering capacity and many
                  other ways. I saw the sheer passion, dedication and can do
                  attitude of the Sri Lankans in this endeavor”; Indaka Raigama
                  further noted. Participating an interview by the Sinhala
                  Service of SBS Radio Australia, Indaka Raigama mentioned that
                  a large number of Sri Lankan engineers are making a
                  significant contribution to the global economy from Sri Lanka
                  and from overseas. He commended the efforts of IESL for
                  recognizing those engineering professionals and making their
                  efforts visible. Talking about the award, Indaka mentioned
                  that it’s a reflection of capability and ingenuity of Sri
                  Lankan engineers who transformed a concept into a commercial
                  product. He also encouraged young engineering professionals to
                  engage in innovations and commercialize their concepts.
                </p>
                <div className="mb-2 noteworthy-link">
                  <Link
                    to="https://www.sbs.com.au/language/english/audio/sri-lankan-innovator-indaka-raigama-honoured-at-iesl-nsw-awards"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    https://www.sbs.com.au/language/english/audio/sri-lankan-innovator-indaka-raigama-honoured-at-iesl-nsw-awards
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NFooter
          prv1="/noteworthy/itelasoft-partners-mobitel-on-iot-devices"
          prv2="/noteworthy/itelasoft-partners-mobitel-on-iot-devices"
          next1="/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
          next2="/noteworthy/How-migrating-to-AWS-eliminated-tech-worries-at-Skwirk"
          titlel="iTelaSoft Partners Mobitel on IoT Devices"
          titler="How migrating to AWS eliminated tech worries at Skwirk"
        />
      </div>
    </Layout>
  );
};
export default News;
